import { message } from 'antd';
import { isValidPhoneNumber } from 'libphonenumber-js';

import { OrderMethod } from '@codegen/generated/graphql';
import { ERROR_MESSAGE } from '@utils/constants';
import { isEmailValid } from '@utils/helpers';

const { NAME_REQUIRED, PHONE_REQUIRED, INVALID_EMAIL } =
  ERROR_MESSAGE.DELIVER_TO;

export const createValidation =
  ({
    cartItemsCount,
    orderMethod,
    address,
    deliveryDate,
    deliveryTime,
    orderName,
    orderPhone,
    orderEmail,
    me,
  }: any) =>
  () => {
    if (cartItemsCount === 0) {
      message.error(ERROR_MESSAGE.CART_ITEMS.NO_ITEMS_CART);

      return false;
    }

    if (!orderMethod) {
      message.error(ERROR_MESSAGE.ORDER_METHOD_REQUIRED);

      return false;
    }

    if (
      !address?.id &&
      !address?.placeId &&
      orderMethod === OrderMethod.Delivery
    ) {
      message.error(ERROR_MESSAGE.INVALID_ADDRESS);

      return false;
    }

    if (!deliveryDate) {
      message.error(ERROR_MESSAGE.DATE_REQUIRED);

      return false;
    }

    if (!deliveryTime) {
      message.error(ERROR_MESSAGE.TIMESLOT_REQUIRED);

      return false;
    }

    if (!orderName) {
      message.error(NAME_REQUIRED);

      return false;
    }

    if (me?.isGuest && (!orderEmail || !isEmailValid(orderEmail))) {
      message.error(INVALID_EMAIL);

      return false;
    }

    if (!orderPhone || !isValidPhoneNumber(orderPhone, 'US')) {
      message.error(PHONE_REQUIRED);

      return false;
    }

    return true;
  };
