import { Select } from 'antd';
import styled from 'styled-components';

import { theme } from '@theme';

const { color } = theme;

interface SelectProps {
  $dark?: boolean;
}

export const StyledSelect = styled(Select)<SelectProps>(
  ({ $dark }) => `
    min-width: 100px;
    .ant-select-arrow > span,
    .ant-select-selection-item {
      color: ${$dark ? color.codGray : color.white} !important;
    }
    .ant-select-selector {
      background-color: ${color.lightGray} !important;
      border: none !important;
      height: 42px !important;
    }
    .ant-select-selector:hover {
      background-color: ${color.lightGray} !important;
    }
  `,
);
