import React from 'react';

import { Skeleton } from 'antd';

import { Icon } from '@components/Icon';
import { Text } from '@components/Text';

import { getFacilityAlphaCode } from '../utils';

import { FacilityOption, FlexLine } from './styles';

interface Props {
  id: string;
  name: string;
  address: string;
  numberOfKitchens: number;
  deliveryInfo: string;
  isActiveFacility: boolean;
  onClick: (value: string) => void;
  loading?: boolean;
  isMobile?: boolean;
}

export const FacilityOptions = ({
  id,
  name,
  address,
  numberOfKitchens,
  deliveryInfo,
  isActiveFacility,
  onClick,
  loading = false,
  isMobile,
}: Props) => {
  const facilityName = `${name}, ${getFacilityAlphaCode(address)}`;
  const getKitchenDescription = (count: number) => {
    if (count > 1) return `${count} brand`;
    else if (count === 1) return `${count} brand`;
    else return 'No brands yet';
  };

  if (loading) {
    return (
      <Skeleton.Button
        active
        style={{ height: 120, width: 365, marginBottom: '24px' }}
      />
    );
  }

  return (
    <FacilityOption
      isMobile={isMobile}
      active={isActiveFacility}
      onClick={() => onClick(id)}
    >
      <Text size="h4" marginB="md">
        {facilityName}
      </Text>
      <FlexLine>
        <Icon src="/icons/building-outlined.svg" />
        <Text marginB="md">{getKitchenDescription(numberOfKitchens)}</Text>
      </FlexLine>
      <FlexLine>
        <Icon src="/icons/car-black-outlined.svg" />
        <Text>{deliveryInfo}</Text>
      </FlexLine>
    </FacilityOption>
  );
};
