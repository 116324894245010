import styled from 'styled-components';

import { theme } from '@theme';

const { breakpoints, color } = theme;

export const LayoutContainer = styled.div<{ isMenuPage: boolean }>(
  ({ isMenuPage }) => `
  background-color:  ${isMenuPage ? color.white : color.springWood};
  height: 100%;
  overflow: auto;
  @${breakpoints.desktop} {
    margin-bottom: 10;
  }
}`,
);

export const StickyWrapper = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 88px;
  z-index: 2;
  @${breakpoints.mobile} {
    top: 18px;
  }
`;
