import { ChangeEvent } from 'react';

import { FormInstance } from 'antd';
import parsePhoneNumber from 'libphonenumber-js';
import { isEmpty } from 'lodash';

import {
  Allergy,
  CheckAddressDocument,
  CheckAddressQuery,
  DishType,
  KitchenMenuItemInMenuFragment,
} from '@codegen/generated/graphql';
import { getApolloClient, GetHeadersProps } from '@lib/apollo';
import { auth } from '@lib/firebase';

import { dishTypeFilterValues, allergyFilterValues } from './constants';
import { DashboardMenuItem, OrderSummary, OverallSummary } from './types';

export const logoutAction = (props: GetHeadersProps) => async () => {
  const client = getApolloClient(props);
  client.resetStore();
};

export const signUp = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => auth.createUserWithEmailAndPassword(email, password);

export const updateEmail = async (email: string) =>
  auth.currentUser?.updateEmail(email);

export const getCurrentUser = () => auth.currentUser;

export const deleteUser = async () => auth.currentUser?.delete();

export const resetPassword = ({ email }: { email: string }) =>
  auth.sendPasswordResetEmail(email);

// function that replaces the parameters of the route path with the arguments of the function using regex
// https://stackoverflow.com/a/52899009
export const getRoute = (path: string, ...args: string[]) => {
  let count = -1;

  return path.replace(/:[a-zA-Z?]+/g, (match) => {
    count += 1;

    return args[count] !== undefined ? args[count] : match;
  });
};

export const checkAddress = async (
  props: GetHeadersProps,
  facilityId: string,
  placeId: string,
) => {
  const client = getApolloClient(props);
  const response = await client.query<CheckAddressQuery>({
    fetchPolicy: 'network-only',
    query: CheckAddressDocument,
    variables: {
      placeId,
    },
  });

  return response?.data?.customerDeliveryInfos?.find(
    (deliveryInfo) =>
      deliveryInfo.facilityId === facilityId && deliveryInfo.isDeliverable,
  );
};

export const isEmailValid = (email: string) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

export const orderSummary: OrderSummary = ({
  subtotal,
  delivery,
  taxFees,
  promoDiscount,
  giftCardsDiscount,
  total,
  tip,
}) => {
  const orderCost: OverallSummary = {
    subtotal,
    taxFees,
    delivery,
    promoDiscount,
    giftCardsDiscount,
    total,
    tip,
  };

  return orderCost;
};

// https://gist.github.com/telekosmos/3b62a31a5c43f40849bb#gistcomment-2341219
export const uniqArray = (arr: any[]) =>
  [...new Set(arr.map((o) => JSON.stringify(o)))].map((s) => JSON.parse(s));

export const objectsAreSame = (x: any, y: any) => {
  let objectsAreSame = true;
  for (const propertyName in x) {
    if (x[propertyName] !== y[propertyName]) {
      objectsAreSame = false;
      break;
    }
  }

  return objectsAreSame;
};

const applyFilterFactory =
  ({
    dishTypeFilters,
    allergyFilters,
  }: {
    dishTypeFilters: DishType[];
    allergyFilters: Allergy[];
  }) =>
  (menuItem: KitchenMenuItemInMenuFragment) => {
    if (
      dishTypeFilters.length > 0 &&
      !dishTypeFilters.every((dishFilter) =>
        menuItem.dishTypes.includes(dishFilter),
      )
    ) {
      return false;
    }

    if (
      allergyFilters.length > 0 &&
      !allergyFilters.every((allergyFilter) =>
        menuItem.allergies.includes(allergyFilter),
      )
    ) {
      return false;
    }

    return true;
  };

export const dishTypeFilterItems = (
  menuItems: KitchenMenuItemInMenuFragment[],
  filters: string[],
) => {
  const allergyFilters = allergyFilterValues
    .filter((allergyFilter) => filters.includes(allergyFilter.value))
    .map((filter) => filter.value);

  const dishTypeFilters = dishTypeFilterValues
    .filter((dishTypeFilter) => filters.includes(dishTypeFilter.value))
    .map((filter) => filter.value);

  const applyFilters = applyFilterFactory({ dishTypeFilters, allergyFilters });

  return menuItems.filter(applyFilters);
};

export const checkHasNoAllergiesAndDishTypes = (
  allItems: DashboardMenuItem[],
) => {
  return allItems.every(
    (item) => isEmpty(item.allergies) && isEmpty(item.dishTypes),
  );
};

export const fbqDefined = () => {
  return typeof window !== 'undefined' && typeof window.fbq === 'function';
};

export const getInitials = (text: string) => {
  const initials = text.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);

  return initials?.join('').toLocaleUpperCase();
};

export const phoneChangeHandler = (
  e: ChangeEvent<HTMLInputElement>,
  form: FormInstance,
) => {
  const { value } = e.target;
  const phoneNumber = parsePhoneNumber(value, 'US');
  form.setFieldsValue({ phone: phoneNumber?.formatNational() });
};

export const getSubdomain = (url: string) => {
  let domain = url;
  if (url.includes('://')) {
    [, domain] = url.split('://');
  }
  const [subdomain] = domain.split('.');

  return subdomain;
};
