import { useState } from 'react';

import { DeliveryOption, OrderMethod } from '@codegen/generated/graphql';
import { UrlKey, useStorageState } from '@lib/appContext/helpers';
import { DeliveryTime } from '@lib/appContext/types';
import { INITIAL_CHECKOUT_ERRORS } from '@utils/constants';
import { CheckoutErrors, DeliverTo, GeoAddress } from '@utils/types';

export const useStateManager = (initialValues: any) => {
  const filters = useState<string[]>([]);

  const note = useState<string>(initialValues.note);

  const deliveryOption = useState<DeliveryOption>(initialValues.deliveryOption);
  const calculatingCart = useState<boolean>(initialValues.calculatingCart);
  const orderConfirmationDisabled = useState<boolean>(
    initialValues.orderConfirmationDisabled,
  );

  const deliverTo = useState<DeliverTo>(initialValues.deliverTo);
  const checkoutErrors = useState<CheckoutErrors>(INITIAL_CHECKOUT_ERRORS);

  const orderName = useState('');
  const orderPhone = useState('');
  const orderEmail = useState('');
  const numberOfGuests = useState(initialValues.numberOfGuests);

  // MENU
  const kitchenId = useStorageState<string>(
    UrlKey.kitchenId,
    initialValues.kitchenId,
  );
  const categoryId = useStorageState(
    UrlKey.categoryId,
    initialValues.categoryId,
  );

  // CART
  const deliveryTime = useStorageState<DeliveryTime>(
    UrlKey.deliveryTime,
    initialValues.deliveryTime,
  );
  const deliveryDate = useStorageState<Date>(
    UrlKey.deliveryDate,
    initialValues.deliveryDate,
  );
  const orderMethod = useStorageState<OrderMethod>(
    UrlKey.orderMethod,
    initialValues.orderMethod,
  );
  const address = useStorageState<GeoAddress>(
    UrlKey.address,
    initialValues.address,
  );

  // GUEST
  const preLoginMenuItem = useStorageState(
    UrlKey.preLoginMenuItem,
    initialValues.preLoginMenuItem,
  );

  return {
    kitchenId,
    categoryId,
    filters,
    address,
    note,
    orderMethod,
    deliveryOption,
    calculatingCart,
    orderConfirmationDisabled,
    deliveryDate,
    deliveryTime,
    deliverTo,
    checkoutErrors,
    preLoginMenuItem,
    orderName,
    orderPhone,
    orderEmail,
    numberOfGuests,
  };
};
