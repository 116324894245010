import { FC } from 'react';

import {
  ApolloClient,
  ApolloClientOptions,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { getTenantFromStorage } from '@utils/tenant';
import { Platform } from '@utils/types';

import {
  INITIAL_VALUES,
  LS_FACILITY_ID_KEY,
  LS_FACILITY_TZ_KEY,
} from './facilityContext';
import { auth } from './firebase';

const httpLink = createHttpLink({
  uri: process.env.CATERING_APP_API_URL || 'http://localhost:4000',
});

export interface GetHeadersProps {
  platform: Platform;
}

const getHeaders =
  ({ platform }: GetHeadersProps) =>
  async () => {
    const token = await auth.currentUser?.getIdToken();

    return {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        platform,
        tenant_id: getTenantFromStorage()?.id,
        facility_id:
          localStorage.getItem(LS_FACILITY_ID_KEY) || INITIAL_VALUES.facilityId,
        timezone:
          localStorage.getItem(LS_FACILITY_TZ_KEY) || INITIAL_VALUES.facilityTz,
      },
    };
  };

const authLink = (props: GetHeadersProps) => setContext(getHeaders(props));

export const options = (
  props: GetHeadersProps,
): ApolloClientOptions<NormalizedCacheObject> => ({
  cache: new InMemoryCache({
    dataIdFromObject: (data) => {
      // Handles repeating ids on categories
      if (data.__typename === 'MenuCategory') {
        return undefined;
      }

      if (!data.id || !data.__typename) {
        return undefined;
      }

      return `${data.__typename}:${data.id}`;
    },
  }),
  link: from([authLink(props), httpLink]),
  ssrMode: typeof window === 'undefined',
});

const cateringClient = new ApolloClient(
  options({ platform: Platform.Catering }),
);
const consumerClient = new ApolloClient(
  options({ platform: Platform.Consumer }),
);

export const getApolloClient = (props: GetHeadersProps) =>
  props.platform === Platform.Consumer ? consumerClient : cateringClient;

export const ApolloProviderComponent: FC<{
  platform: GetHeadersProps['platform'];
}> = ({ children, platform }) => {
  const client = getApolloClient({ platform });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
