import { isWithinInterval } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { DeliveryTime } from '@lib/appContext/types';
import { getLocalizedNow } from '@utils/dateTime';

export const isTimeslotActive = (
  timeslot: DeliveryTime,
  facilityTz: string,
) => {
  if (!timeslot?.start || !timeslot?.end) return false;
  const now = getLocalizedNow(new Date(), facilityTz);
  const start = utcToZonedTime(timeslot.start, facilityTz);
  const end = utcToZonedTime(timeslot.end, facilityTz);

  return isWithinInterval(now, { start, end });
};
