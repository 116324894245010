import styled from 'styled-components';

import { Container } from '@components/Container';
import { theme } from '@theme';

const { color, margin } = theme;

interface Props {
  isMobile?: boolean;
}

export const HeaderContainer = styled.div<Props>(
  ({ isMobile }) => `
  display: flex;
  align-items: center;
  background-color: ${color.black};
  height: 88px;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
`,
);

export const LogoFacilityUser = styled(Container)<Props>(
  ({ isMobile }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
`,
);

export const LogoWrapper = styled.div`
  min-width: 130px;
`;

export const Logo = styled.img(
  ({ src }: { src: string }) => `
  background: url('${src}') no-repeat;
  background-size: contain;
  background-position: left;
  height: 48px;
  cursor: pointer;
`,
);

export const DetailsWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${color.black};
  padding-top: ${margin.sm};
`;

export const WidgetWrapper = styled.div`
  display: flex;
  gap: 16px;
`;
