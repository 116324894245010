import { useContext, useEffect, useState } from 'react';

import { Select } from 'antd';
import { useRouter } from 'next/router';

import { Button } from '@components/Button';
import { AppContext } from '@lib/appContext';
import { clearUrlKeysFromStorage } from '@lib/appContext/helpers';
import { auth } from '@lib/firebase';
import { logoutAction } from '@utils/helpers';
import { ROUTES } from '@utils/pages';

import { StyledSelect } from './styles';

const { Option } = Select;

const options = [
  {
    key: 'my-orders',
    label: 'My Orders',
    link: ROUTES.MY_ORDERS.route,
  },
  {
    key: 'my-profile',
    label: 'My Profile',
    link: ROUTES.MY_PROFILE.route,
  },
  {
    key: 'help',
    label: 'Help',
    link: ROUTES.HELP.route,
  },
  {
    key: 'sign-out',
    label: 'Sign out',
    link: '',
  },
];

export const UserWidget = () => {
  const { push, pathname, query } = useRouter();
  const { setAddress, platform, me } = useContext(AppContext);
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);

  const signInPath = ROUTES.SIGN_IN.route;
  const user = me;
  const isGuest = user?.isGuest;
  const opts = isGuest
    ? options.filter((option) => option.label != 'Profile')
    : options;

  useEffect(() => {
    window.addEventListener('scroll', () => setIsWidgetOpen(false));

    return () =>
      window.removeEventListener('scroll', () => setIsWidgetOpen(false));
  }, []);

  if (!user)
    return (
      <Button
        size="sm"
        icon={<img src="/icons/user.svg" />}
        type="tertiary"
        text="Sign In"
        onClick={() => push({ pathname: signInPath, query })}
      />
    );

  return (
    <StyledSelect
      open={isWidgetOpen}
      onDropdownVisibleChange={setIsWidgetOpen}
      onSelect={async (_, option) => {
        if (option.key === 'sign-out') {
          setAddress(null);
          await auth.signOut();
          await logoutAction({ platform });
          clearUrlKeysFromStorage();
          push(signInPath);

          return false;
        }
        push(option.value);

        return false;
      }}
      value="user"
    >
      <Option className="invisible" value="user">
        <img src="/icons/user.svg" /> {user?.name}
      </Option>
      {opts.map((option) => {
        return (
          <Option
            className={pathname === option.link ? 'active' : ''}
            key={option.key}
            value={option.link}
          >
            {option.label}
          </Option>
        );
      })}
    </StyledSelect>
  );
};
