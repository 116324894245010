import { OrderMethod } from '@codegen/generated/graphql';
import { INITIAL_CHECKOUT_ERRORS, INITIAL_SUMMARY } from '@utils/constants';
import { KITCHEN_OPTIONS, Platform } from '@utils/types';

import { IAppContextProps } from './types';

// TODO: initial values should be removed
export const INITIAL_VALUES: IAppContextProps = {
  me: null,

  orderName: '',
  setOrderName: (_) => undefined,

  orderEmail: '',
  setOrderEmail: (_) => undefined,

  orderPhone: '',
  setOrderPhone: (_) => undefined,

  preLoginMenuItem: { id: '', extras: [] },
  setPreLoginMenuItem: (_) => undefined,

  address: null,
  setAddress: (_) => undefined,

  note: '',
  setNote: (_) => undefined,

  orderMethod: OrderMethod.PickUp,
  setOrderMethod: (_) => undefined,

  deliveryDate: new Date(),
  setDeliveryDate: (_) => undefined,

  deliveryTime: undefined,
  setDeliveryTime: (_) => undefined,

  isOrderForNow: true,

  deliverTo: 'me',
  setDeliverTo: (_) => undefined,

  numberOfGuests: '',
  setNumberOfGuests: (_) => undefined,

  basicCheckoutValidation: () => false,

  checkoutErrors: INITIAL_CHECKOUT_ERRORS,
  setCheckoutErrors: (_) => undefined,

  summary: INITIAL_SUMMARY,

  calculatingCart: false,
  setCalculatingCart: (_) => undefined,

  orderConfirmationDisabled: false,
  setOrderConfirmationDisabled: (_) => undefined,

  platform: Platform.Catering,
  extraParams: '',

  cartItemsCount: 0,
  isMobile: false,

  filters: [''],
  setFilters: (_) => undefined,

  kitchenId: KITCHEN_OPTIONS.All,
  setKitchenId: (_) => undefined,

  categoryId: undefined,
  setCategoryId: (_) => undefined,

  resetCartContext: () => undefined,
  refetchCartCost: () => undefined,
  customerCartLoading: false,
  tenant: undefined as any,
};

export const ORDER_SUMMARY_DEFAULT_VALUES = {
  subtotal: 0,
  delivery: 0,
  fee: 0,
  promoDiscount: 0,
  giftCardsDiscount: 0,
  tax: 0,
  taxFees: 0,
  tip: 0,
  total: 0,
};
