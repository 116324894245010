import { logEvent as firebaseLog } from '@lib/firebase';

type Item = {
  id: string;
  name?: string;
  coupon?: string;
  brand?: string;
  category?: string;
  list_name?: string;
  list_position?: number;
  price?: number;
  quantity?: number;
  variant?: string;
};

export const logEvent = async (
  category: string,
  params: {
    message: string;
    id: string;
    affiliation?: string;
    coupon?: string;
    currency?: string;
    shipping?: number;
    tax?: number;
    transaction_id?: string;
    value?: number;
    items?: Item[];
  },
) => {
  return firebaseLog?.(category, params, {
    global: true,
  });
};
