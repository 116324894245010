import { useContext } from 'react';

import { useRouter } from 'next/router';

import { Text } from '@components/Text';
import { AppContext } from '@lib/appContext';
import { logEvent } from '@utils/analytics';
import { ROUTES } from '@utils/pages';

import { CartBadge, CartButton } from './styles';

export const CartWidget = () => {
  const { push } = useRouter();
  const {
    extraParams,
    me,

    cartItemsCount,
    isMobile,
  } = useContext(AppContext);

  const user = me;
  if (!user) return null;
  const onConfirm = async () => {
    await logEvent('CHECKOUT_BAR', {
      id: me?.id ?? '',
      message: `clicked "View cart" button`,
    });
    push(ROUTES.CHECKOUT.route + extraParams);
  };

  return (
    <CartButton onClick={onConfirm}>
      {Boolean(cartItemsCount) && (
        <CartBadge>
          <Text size="large" color="white">
            {cartItemsCount}
          </Text>
        </CartBadge>
      )}
      <img src="/icons/cart.svg" />
      {isMobile ? '' : 'View Cart'}
    </CartButton>
  );
};
