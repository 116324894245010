import React from 'react';

import { Skeleton, Space } from 'antd';

import { Button } from '@components/Button';
import { ExpandableText } from '@components/ExpandableText';
import { Icon } from '@components/Icon';
import { Map } from '@components/Map';
import { Text } from '@components/Text';

import { KitchenLogos } from './KitchenLogos';

import { FacilityInfoSection, RightSectionRow, ButtonRow } from './styles';

const mapContainer = {
  width: 'auto',
  minHeight: '200px',
  marginBottom: '15px',
  borderRadius: '12px',
};

interface Props {
  address: string;
  cuisines: string;
  kitchenLogos: string[];
  coordinates: { lat: number; lng: number };
  onConfirm: () => void;
  loading?: boolean;
}

export const FacilityInfo = ({
  address,
  cuisines,
  kitchenLogos,
  coordinates,
  onConfirm,
  loading = false,
}: Props) => {
  return (
    <FacilityInfoSection>
      <Map initialCenter={coordinates} mapContainer={mapContainer} />
      <RightSectionRow>
        {loading ? (
          <Space>
            <Skeleton.Avatar active size="small" />
            <Skeleton.Input style={{ width: 300 }} active size="small" />
          </Space>
        ) : (
          <>
            <Icon src="/icons/map_pin_small.svg" />
            <Text>{address}</Text>
          </>
        )}
      </RightSectionRow>
      <RightSectionRow>
        {loading ? (
          <Space>
            <Skeleton.Avatar active size="small" />
            <Skeleton.Input style={{ width: 300 }} active size="small" />
          </Space>
        ) : (
          cuisines && (
            <>
              <Icon src="/icons/dining-circled-black.svg" />
              <Text lineHeight={22}>
                <ExpandableText
                  inputText={cuisines}
                  maxChars={75}
                  separator={','}
                />
              </Text>
            </>
          )
        )}
      </RightSectionRow>
      <KitchenLogos logos={kitchenLogos} />
      <ButtonRow>
        <Button
          text="Confirm"
          onClick={() => onConfirm()}
          size="sm"
          fluid={false}
        />
      </ButtonRow>
    </FacilityInfoSection>
  );
};
