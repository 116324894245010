import { Button } from 'antd';
import { darken, lighten } from 'polished';
import styled from 'styled-components';

import { parseMargin, parseColor, theme } from '@theme';

import { Props, ButtonTypes, ButtonValues, ButtonSizes } from './types';

const { padding, color, border } = theme;

// UI KIT for buttons: https://www.figma.com/file/T4dFWnKS0hDQ3nmKvWBlDx/Catering?node-id=655%3A61

const buttonHeights: { [key in ButtonSizes]: string } = {
  xs: '35px',
  sm: '48px',
  normal: '56px',
};

const darkValues: { [key in ButtonTypes]: ButtonValues } = {
  primary: {
    normal: {
      background: color.codGray,
      border: 'none',
      color: color.white,
    },
    hover: {
      background: color.shipGray,
      border: 'none',
      color: color.white,
    },
  },
  secondary: {
    normal: {
      background: color.white,
      border: border.secondary,
      color: color.black,
    },
    selected: {
      background: color.black,
      border: color.black,
      color: color.white,
    },
    hover: {
      background: color.beige,
      border: border.secondary,
      color: color.black,
    },
  },
  secondaryAlt: {
    normal: {
      background: color.white,
      border: border.secondary,
      color: color.black,
    },
    selected: {
      background: color.selected,
      border: `1px solid ${lighten(0.4, color.selected)}`,
      color: color.white,
    },
    hover: {
      background: color.beige,
      border: border.secondary,
      color: color.black,
    },
  },
  flat: {
    normal: {
      background: color.transparent,
      border: 'none',
      color: color.shipGray,
    },
    hover: {
      background: color.transparent,
      border: 'none',
      color: color.black,
    },
  },
  tertiary: {
    normal: {
      background: parseColor(['black', 0.5]),
      border: 'none',
      color: color.white,
    },
    hover: {
      background: parseColor(['black', 0.8]),
      border: 'none',
      color: color.white,
    },
  },
};

const lightValues: { [key in ButtonTypes]: ButtonValues } = {
  primary: {
    normal: {
      background: color.springWood,
      border: 'none',
      color: color.black,
    },
    hover: {
      background: color.beige,
      border: 'none',
      color: color.black,
    },
  },
  secondary: {
    normal: {
      background: color.codGray,
      border: border.secondary,
      color: color.white,
    },
    hover: {
      background: color.beige,
      border: border.secondary,
      color: color.black,
    },
  },
  secondaryAlt: {
    normal: {
      background: color.codGray,
      border: border.secondary,
      color: color.white,
    },
    selected: {
      background: color.selected,
      border: `1px solid ${darken(0.4, color.selected)}`,
      color: color.white,
    },
    hover: {
      background: color.beige,
      border: border.secondary,
      color: color.black,
    },
  },
  flat: {
    normal: {
      background: color.transparent,
      border: 'none',
      color: color.springWood,
    },
    hover: {
      background: color.transparent,
      border: 'none',
      color: color.beige,
    },
  },
  tertiary: {
    normal: {
      background: parseColor(['springWood', 0.1]),
      border: 'none',
      color: color.white,
    },
    hover: {
      background: parseColor(['springWood', 0.2]),
      border: 'none',
      color: color.white,
    },
  },
};

// #FIXME: margins shouldn't be inside the component, height shouldn't be
export const StyledButton = styled(
  ({
    fluid,
    block,
    marginT,
    marginB,
    marginL,
    marginR,
    selected,
    light,
    ...rest
  }) => <Button {...rest} />,
)(
  ({
    type = 'primary',
    fluid,
    block,
    marginT = 0,
    marginB = 0,
    marginL = 0,
    marginR = 0,
    size = 'normal',
    selected,
    light,
    shape,
  }: Props) => {
    const dim = light ? darken : lighten;
    const values = (light ? lightValues : darkValues)[type];
    const normal = (selected && values.selected) || values.normal;
    const hover = (selected && values.selected) || values.hover;

    return `
      padding: 0 ${type === 'flat' || shape === 'circle' ? 0 : padding.xl};
      margin: ${parseMargin(marginT)} ${parseMargin(marginR)} ${parseMargin(
      marginB,
    )} ${parseMargin(marginL)};
      height: ${buttonHeights[size]};
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-right: ${padding.sm}
      }

      ${fluid || block ? `width: 100%; justify-content: center;` : ''}

      &, &:focus, &:active {
        background: ${normal.background};
        border: ${normal.border};
        span {
          color: ${normal.color};
        }
      }

      &:disabled {
        background: ${dim(0.4, normal.background)};
        span {
          color: ${dim(0.4, normal.color)} !important;
        }
      }

      &:disabled:hover {
        background: ${dim(0.5, normal.background)};
      }

      &:hover {
          background: ${hover.background};
          border: ${hover.border};
          span {
            color: ${hover.color};
          }
      }
      `;
  },
);
