import { sortBy, uniq } from 'lodash';

import {
  AllFacilitiesQuery,
  AllKitchensQuery,
  OrderMethod,
} from '@codegen/generated/graphql';

// Requires the address format to be "street, city, ID 83642, state"
export const getFacilityAlphaCode = (address = '') => {
  const addressArray = address?.trim()?.split(',', 3);
  const postalArray = addressArray[2]?.split(' ');

  return postalArray?.[1];
};

export const getCuisinesList = (
  data: AllKitchensQuery['customerKitchensAll'] = [],
) => {
  const cuisines = data.map((k) => k.cuisine?.trim());

  return uniq(cuisines).join(', ');
};

export const sortByCreatedAt = (
  data: AllFacilitiesQuery['customerFacilities'],
) => sortBy(data, ['createdAt']);

export const getDeliveryInfo = (orderMethods: OrderMethod[]) => {
  const includesPickup = orderMethods?.includes(OrderMethod.PickUp);

  return `Delivery ${includesPickup ? ' • Pickup' : ''}`;
};
