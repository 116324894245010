import { RuleObject } from 'antd/lib/form';
import {
  MenuItemLabel,
  Allergy,
  DishType,
  BusinessType,
} from 'codegen/generated/graphql';
import { isValidPhoneNumber } from 'libphonenumber-js';

import { CheckoutErrors, OverallSummary } from './types';

export const IS_SSR = typeof window === 'undefined';
export const GA_TRACKING_ID = 'G-WR63M7JHV0';
export const FB_Pixel_ID = '115528263881285';

export const APP_LINKS = {
  PLAYSTORE:
    'https://play.google.com/store/apps/details?id=com.crave.consumerapp',
  APPSTORE: 'https://apps.apple.com/us/app/crave-delivery/id1505759548',
};

export const dishTypeFilterValues = [
  { value: DishType.KidFriendly, label: 'Kid friendly' },
  { value: DishType.Alcohol, label: 'Alcohol' },
  { value: DishType.Vegan, label: 'Vegan' },
  { value: DishType.Vegetarian, label: 'Vegetarian' },
];

export const allergyFilterValues = [
  { value: Allergy.PeanutFree, label: 'Peanut free' },
  { value: Allergy.DairyFree, label: 'Dairy free' },
  { value: Allergy.GlutenFree, label: 'Gluten free' },
  { value: Allergy.ShellfishFree, label: 'Shellfish free' },
  { value: Allergy.TreeNutFree, label: 'Tree nut free' },
];

export const ITEM_LABELS: { [k in MenuItemLabel]: string } = {
  [MenuItemLabel.New]: 'New',
  [MenuItemLabel.Featured]: 'Featured',
  [MenuItemLabel.ThreeCourses]: 'Three Courses',
  [MenuItemLabel.Special]: 'Special',
};

export const ITEM_ALLERGIES: { [k in Allergy]: string } = {
  [Allergy.DairyFree]: 'Dairy Free',
  [Allergy.GlutenFree]: 'Gluten Free',
  [Allergy.PeanutFree]: 'Peanut Free',
  [Allergy.ShellfishFree]: 'Shellfish Free',
  [Allergy.TreeNutFree]: 'Tree nut Free',
};

export const ITEM_DISHTYPES: { [k in DishType]: string } = {
  [DishType.Alcohol]: 'Alcohol',
  [DishType.Vegetarian]: 'Vegetarian',
  [DishType.Vegan]: 'Vegan',
  [DishType.KidFriendly]: 'Kid friendly',
};

export const ERROR_MESSAGE = {
  DEFAULT: 'Unexpected error occurred. Please try again.',
  ORDER_METHOD_REQUIRED: 'Please select order method',
  INVALID_ADDRESS:
    'Invalid delivery address. Please update your address and try again.',
  CART_ITEMS: {
    LIMITED_AVAILABILITY:
      'This item has a limited quantity, no more can be added to the cart',
    NO_ITEMS_CART: "You don't have any items in your cart",
  },
  DATE_REQUIRED: 'Please select the date',
  TIMESLOT_REQUIRED: 'Please select the timeslot',
  NUMBER_OF_GUESTS_REQUIRED: 'Please indicate number of guests',
  CHECKOUT_INVALID_ITEMS:
    'You have items on your cart that are invalid. Please remove them.',
  CHECKOUT_PICKUP_ONLY:
    "You have items on your cart that can't be delivered. Please remove them.",
  FOR_PICKUP_ONLY: 'This item is for pickup only.',
  INVALID_CURRENT_PASSWORD: 'Invalid current password.',
  DELIVER_TO: {
    NAME_REQUIRED: 'Please enter customer name',

    EMAIL_REQUIRED:
      'Please type the email of the person who will be present at delivery',
    PHONE_REQUIRED: 'Please enter valid phone number',

    INVALID_EMAIL:
      'Invalid email of the person who will be present at delivery',
  },
  UNEXPECTED_ERROR: 'Unexpected error occured.',
  MIN_GRATUITY: 'Minimum gratuity is 0',
  MAX_GRATUITY: 'Maximum gratuity is 99',
  BRAINTREE_PAYMENT_ERROR:
    'Unable to process the payment at the moment. Please try again later.',
  SIGN_IN_FAILED: 'Failed to sign in, please try again.',
  SIGN_UP_FAILED: 'Failed to sign up, please try again.',
  NO_DRIVER_AVAILABLE: 'Unable to find a driver, please try again later.',
  MENU_ITEM_MODAL: {
    ITEM_UNAVAILABLE: 'You cannot make changes on extras for unavailable item.',
    EXTRA_REQUIRED: 'Required extras are not selected: ',
    QUANTITY_INCORRECT: 'The minimum or maximum amount is incorrect: ',
  },
};

export const PHONE_VALIDATION_RULES = [
  {
    required: true,
    validator: (_: RuleObject, value: string) => {
      if (value && isValidPhoneNumber(value, 'US')) {
        return Promise.resolve();
      } else {
        return Promise.reject('Invalid number');
      }
    },
    message: 'Please enter a valid US number.',
  },
];

export const INITIAL_CHECKOUT_ERRORS: CheckoutErrors = {
  pickupOnlyItemIds: [],
  unavailableItemIds: [],
  errors: [],
  promoErrors: [],
};

export const INITIAL_SUMMARY: OverallSummary = {
  subtotal: 0,
  taxFees: 0,
  delivery: 0,
  total: 0,
  tip: 0,
  promoDiscount: 0,
  giftCardsDiscount: 0,
};

export const BUSINESS_TYPES = [
  ...[
    {
      label: 'Construction',
      value: BusinessType.Construction,
    },
    {
      label: 'Finance',
      value: BusinessType.Finance,
    },
    {
      label: 'Events',
      value: BusinessType.Events,
    },
    {
      label: 'Accounting',
      value: BusinessType.Accounting,
    },
    {
      label: 'Legal',
      value: BusinessType.Legal,
    },
    {
      label: 'Real Estate',
      value: BusinessType.RealEstate,
    },
    {
      label: 'Education',
      value: BusinessType.Education,
    },
    {
      label: 'Government',
      value: BusinessType.Government,
    },
    {
      label: 'Non-Profits',
      value: BusinessType.NonProfits,
    },
    {
      label: 'Retail',
      value: BusinessType.Retail,
    },
    {
      label: 'Transportation',
      value: BusinessType.Transportation,
    },
    {
      label: 'Telecommunication',
      value: BusinessType.Telecommunication,
    },
    {
      label: 'Technology',
      value: BusinessType.Technology,
    },
    {
      label: 'Beauty',
      value: BusinessType.Beauty,
    },
  ].sort((a, b) => a.label.localeCompare(b.label)),
  {
    label: 'Other',
    value: BusinessType.Other,
  },
];

export const CONTACT_HELP = {
  phone: '(208) 271-9727',
  email: 'catering@cravedelivery.com',
};

export const IMG_PLACE_HOLDERS = {
  menu_item: '/placeholders/menu-item.svg',
};

export const ITEM_IN_CART_QUERY = 'ITEM_IN_CART_QUERY';
