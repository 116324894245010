import { useState } from 'react';

import { message } from 'antd';
import { mergeWith } from 'lodash';
import moment, { isMoment } from 'moment';
import { NextRouter, useRouter } from 'next/router';

import {
  CreateUserAddressMutationFn,
  SimpleAddressFragment,
  UserDocument,
  OrderMethod,
  UpdateAndSetUserAddressMutationFn,
} from '@codegen/generated/graphql';
import { GeoAddress } from '@utils/types';

import { INITIAL_VALUES } from './constants';

export const getDefaultAddress = (
  defaultAddressId: string,
  addresses?: SimpleAddressFragment[],
): GeoAddress => {
  const formattedAddress =
    addresses?.find((address) => address.id === defaultAddressId)
      ?.formattedAddress ?? '';
  const placeId =
    addresses?.find((address) => address.id === defaultAddressId)?.placeId ??
    '';
  const note =
    addresses?.find((address) => address.id === defaultAddressId)?.note ?? '';

  return { id: defaultAddressId, placeId, label: formattedAddress, note };
};

// currently stored url keys are specified here
export enum UrlKey {
  'address' = 'address',
  'orderMethod' = 'orderMethod',
  'deliveryDate' = 'deliveryDate',
  'deliveryTime' = 'deliveryTime',
  'kitchenId' = 'kitchenId',
  'categoryId' = 'categoryId',
  'preLoginMenuItem' = 'preLoginMenuItem',
}

export const clearUrlKeysFromStorage = () => {
  Object.values(UrlKey).map((key) => sessionStorage.removeItem(key));
};

const getInitialValuesFromSessionStorage = () => {
  const populatedState = {} as { [key in UrlKey]: any };
  [
    UrlKey.address,
    UrlKey.deliveryDate,
    UrlKey.deliveryTime,
    UrlKey.orderMethod,
    UrlKey.kitchenId,
    UrlKey.categoryId,
    UrlKey.preLoginMenuItem,
  ].map((key) => {
    const urlValue = sessionStorage.getItem(key);

    // NOTE: we are trying to convert a string from url to date, if it's not a date we use initial value
    const dateFromValue = moment(urlValue);
    let objectFromValue;
    try {
      objectFromValue = JSON.parse(urlValue as string);
    } catch (e) {
      // failed to parse - that's not an object
      // standard check to get the type of the field stored as a string - no action needed
    }

    let updatedValue: any = urlValue;
    if (dateFromValue.isValid()) updatedValue = dateFromValue;
    else if (objectFromValue) updatedValue = objectFromValue;

    populatedState[key as UrlKey] = updatedValue || undefined;
  });

  return populatedState;
};

export const getInitialValues = () => {
  const initialValuesFromSessionStorage = getInitialValuesFromSessionStorage();
  // TODO: fix router to allow shallow routing, until that we will use sessionStorage
  // const initialValuesFromUrl = getInitialValuesFromUrl(query);
  const mergedInitialValues = mergeWith(
    {},
    INITIAL_VALUES,
    initialValuesFromSessionStorage,
    (a, b) => (b === null ? a : undefined),
  );
  if (
    mergedInitialValues.orderMethod === OrderMethod.Delivery &&
    !mergedInitialValues.address
  ) {
    mergedInitialValues.orderMethod = OrderMethod.PickUp;
  }

  return mergedInitialValues;
};

interface WithUpdateUrlProps {
  key: string;
  push: NextRouter['push'];
  query: NextRouter['query'];
}

// TODO: fix router to allow shallow routing, until that we will use sessionStorage
export const withUpdateUrl =
  ({ key, push, query }: WithUpdateUrlProps) =>
  (func: (val: any) => void) =>
  (value: any) => {
    let urlValue;
    if (!value) urlValue = '';
    else if (isMoment(value)) urlValue = value.format('YYYY-MM-DD');
    else if (value instanceof Object) urlValue = JSON.stringify(value);
    else urlValue = value.toString();

    push({ query: { ...query, [key]: urlValue } });
    func(value);
  };

export const withUpdateSessionStorage =
  ({ key }: WithUpdateUrlProps) =>
  (func: (val: any) => void) =>
  (value: any) => {
    let urlValue;
    if (!value) urlValue = '';
    else if (isMoment(value)) urlValue = value.format('YYYY-MM-DD');
    else if (value instanceof Object) urlValue = JSON.stringify(value);
    else urlValue = value.toString();

    sessionStorage.setItem(key, urlValue);
    func(value);
  };

export const useStorageState = <Type extends unknown>(
  key: UrlKey,
  initialValue: Type,
) => {
  const { push, query } = useRouter();
  const [value, setValue] = useState<Type>(
    (sessionStorage.getItem(key) as Type) ?? initialValue,
  );

  return [
    value,
    withUpdateSessionStorage({ key, push, query })(setValue),
  ] as const;
};

interface GetExistingAddressesProps {
  existingAddresses?: GeoAddress[];
  currentAddress: GeoAddress;
}

export const getExistingAddress = ({
  existingAddresses = [],
  currentAddress,
}: GetExistingAddressesProps) =>
  existingAddresses.find(
    (address) => address?.placeId === currentAddress?.placeId,
  );

export const createAddressIfNeeded = async ({
  existingAddresses,
  currentAddress,
  createAddress,
  updateAddress,
  setAddress,
  onError,
}: GetExistingAddressesProps & {
  createAddress: CreateUserAddressMutationFn;
  updateAddress: UpdateAndSetUserAddressMutationFn;
  setAddress: (a: GeoAddress) => void;
  onError?: () => void;
}) => {
  const existingAddress = getExistingAddress({
    existingAddresses,
    currentAddress,
  });

  if (!existingAddress) {
    if (!currentAddress?.placeId) throw new Error('MISSING_ADDRESS');

    try {
      const { data: createAddressData } = await createAddress({
        variables: {
          input: {
            placeId: currentAddress.placeId,
            note: currentAddress.note,
          },
        },
        refetchQueries: [{ query: UserDocument }],
      });
      const newAddress = createAddressData?.customerCreateUserAddress;

      if (!newAddress) throw new Error('no new addresses were created');
      setAddress({
        id: newAddress.id,
        placeId: newAddress.placeId,
        label: newAddress.formattedAddress || '',
        note: newAddress.note,
      });

      return newAddress;
    } catch (error) {
      message.error(
        `Failed to create a new delivery address: ${(error as Error).message}`,
      );

      if (onError) onError();

      return;
    }
  } else {
    const addressId = existingAddress.id;

    if (!addressId) return; // calming down the compiler about id being there
    updateAddress({
      variables: {
        id: addressId,
        input: {
          id: addressId,
          placeId: currentAddress?.placeId || existingAddress.placeId,
          note: currentAddress?.note || existingAddress.note,
        },
      },
    });
  }
};
