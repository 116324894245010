import { utcToZonedTime } from 'date-fns-tz';
import moment, { Moment } from 'moment-timezone';

import { OperationHoursInput } from '@codegen/generated/graphql';

type Format = 'TIME_ONLY' | 'DATE_ONLY' | 'DATE_AND_TIME' | 'COMMON_DATE_ONLY';

/* Getters */
export const getNormalizedTimeUnit = (timeUnit: number) =>
  timeUnit <= 9 ? `0${timeUnit}` : timeUnit;

export const getLocalizedNow = (now = new Date(), timeZone: string) =>
  utcToZonedTime(now.toISOString(), timeZone);

/* Formatters */
export const formatDate = (
  date: Date | Moment,
  tz: string,
  type: Format = 'DATE_AND_TIME',
) => {
  if (type === 'TIME_ONLY') return moment(date).tz(tz)?.format('hh:mm a');
  if (type === 'DATE_ONLY') return moment(date).tz(tz)?.format('MM/DD/YYYY');
  if (type === 'COMMON_DATE_ONLY')
    return moment(date).tz(tz)?.format('MMMM DD, YYYY');

  return moment(date).tz(tz)?.format('MM/DD/YYYY, hh:mm a');
};

export const daysOfWeek: string[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const getOpeningHours = (hours: OperationHoursInput | undefined) => {
  if (hours) {
    const startTime = `${getNormalizedTimeUnit(
      hours.startHour,
    )}:${getNormalizedTimeUnit(hours.startMinute)}`;
    const endTime = `${getNormalizedTimeUnit(
      hours.endHour,
    )}:${getNormalizedTimeUnit(hours.endMinute)} `;

    return {
      startTime,
      endTime,
    };
  }

  return {
    startTime: undefined,
    endTime: undefined,
  };
};
