import { createContext, FC, useState, useEffect } from 'react';

import { useAllFacilitiesQuery } from '@codegen/generated/graphql';
import { LocationModal } from '@containers/MainLayout/FacilityDetails/LocationModal';
import { Header } from '@containers/MainLayout/Header';
import { LayoutContainer } from '@containers/MainLayout/styles';

import { INITIAL_VALUES } from './constants';
import { Props } from './types';

export { INITIAL_VALUES } from './constants';

// LS = Local Storage
export const LS_FACILITY_ID_KEY = 'facilityId';
export const LS_FACILITY_TZ_KEY = 'facilityTz';
export const LS_LOGO_URL_KEY = 'logoUrl';

export const FacilityContext = createContext<Props>(INITIAL_VALUES);

export const PickFacility = () => (
  <>
    <LayoutContainer isMenuPage={true}>
      <Header />
    </LayoutContainer>
    <LocationModal visible={true} onCancel={() => null} />
  </>
);

export const FacilityContextProvider: FC = ({ children }) => {
  const lsDefined = typeof localStorage !== 'undefined';

  const initialValues = {
    facilityId:
      (lsDefined && localStorage.getItem(LS_FACILITY_ID_KEY)) ||
      INITIAL_VALUES.facilityId,
    facilityTz:
      (lsDefined && localStorage.getItem(LS_FACILITY_TZ_KEY)) ||
      INITIAL_VALUES.facilityTz,
    logoUrl:
      (lsDefined && localStorage.getItem(LS_LOGO_URL_KEY)) ||
      INITIAL_VALUES.logoUrl,
  };

  const { data } = useAllFacilitiesQuery();

  const [facilityId, setFacilityId] = useState(initialValues.facilityId);
  const [facilityTz, setFacilityTz] = useState(initialValues.facilityTz);
  const [selectedFacility, setSelectedFacility] =
    useState<Props['selectedFacility']>();
  const [logoUrl, setLogoUrl] = useState(initialValues.logoUrl);

  useEffect(() => {
    if (lsDefined) {
      localStorage.setItem(LS_FACILITY_ID_KEY, facilityId);
      localStorage.setItem(LS_FACILITY_TZ_KEY, facilityTz);
    }
  }, [facilityId, facilityTz, lsDefined]);

  // FIXME: For some reason, I couldn't get "const" working with the type forwarding - you are welcome to try for the sake of consistency
  function setInStateAndLS<T>(lsName: string, setterFn: (value: T) => void) {
    return (value: T) => {
      lsDefined && localStorage.setItem(lsName, value as unknown as string);
      setterFn(value);
    };
  }

  const handleSetTimeZone = setInStateAndLS('facilityTz', setFacilityTz);
  const handleSetFacilityId = setInStateAndLS('facilityId', setFacilityId);
  const handleSetFacilityLogo = setInStateAndLS('logoUrl', setLogoUrl);

  useEffect(() => {
    if (!data || !facilityId) return;

    const selectedFacility = data.customerFacilities.find(
      ({ id }) => id === facilityId,
    );

    if (!selectedFacility) return;

    handleSetTimeZone(selectedFacility.timeZone);
    handleSetFacilityLogo(selectedFacility.logoUrl ?? '');
    setSelectedFacility(selectedFacility);
  }, [data, facilityId, handleSetFacilityLogo, handleSetTimeZone]);

  return (
    <FacilityContext.Provider
      value={{
        facilityId,
        facilityTz,
        selectedFacility,
        logoUrl,
        setFacilityId: handleSetFacilityId,
      }}
    >
      {facilityId ? children : <PickFacility />}
    </FacilityContext.Provider>
  );
};
