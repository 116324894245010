import React from 'react';

import styled from 'styled-components';

import { SIZES, parseMargin } from '@theme';

interface IconProps {
  src: string; // Possibility to list out all our Icons
  width?: number;
  height?: number;
  marginRight?: SIZES;
  onClick?: () => void;
}

const IconComponent = styled.img<IconProps>(
  ({ width = 20, height = 20, marginRight = 'md' }) =>
    `
    width: ${width}px;
    height: ${height}px;
    margin-right: ${parseMargin(marginRight)}
  `,
);

export const Icon = ({ src, ...rest }: IconProps) => {
  return <IconComponent src={src} {...rest} />;
};
