import React, { useState } from 'react';

import { truncate } from 'lodash';
import styled from 'styled-components';

export const ToggleReadMore = styled.span`
  color: blue;

  &:hover {
    cursor: pointer;
  }
`;

interface Props {
  inputText: string;
  maxChars: number;
  separator?: string;
}

export const ExpandableText = ({
  inputText,
  maxChars,
  separator = ' ',
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const truncatedText = truncate(inputText, {
    length: maxChars,
    separator,
  });
  const maxCharExceeded = inputText.length > maxChars;
  const hiddenText = maxCharExceeded ? `${truncatedText}` : inputText;
  const finalText = isExpanded ? inputText : hiddenText;

  if (!maxCharExceeded) {
    return <span>{inputText}</span>;
  }

  return (
    <>
      {hiddenText ? (
        <>
          {finalText}
          <ToggleReadMore onClick={() => setIsExpanded(!isExpanded)}>
            {` ${isExpanded ? 'Hide' : 'and more'}`}
          </ToggleReadMore>
        </>
      ) : (
        inputText
      )}
    </>
  );
};
