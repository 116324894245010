import React from 'react';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Skeleton } from 'antd';
import { CSSProperties } from 'styled-components';

import { GOOGLE_MAPS_API_KEY } from '@lib/env';

interface Props {
  initialCenter: { lat: number; lng: number };
  mapContainer?: CSSProperties;
  zoom?: number;
}

/**
 * Map Component from https://react-google-maps-api-docs.netlify.app/
 * @param mapContainer container styles
 * @param initialCenter initial centre of the map
 * @param zoom initial zoom of the map
 */

export const Map = ({ initialCenter, mapContainer, zoom = 15 }: Props) => (
  <LoadScript
    googleMapsApiKey={GOOGLE_MAPS_API_KEY ?? ''}
    loadingElement={
      <Skeleton.Button active style={{ height: '100%', width: '100%' }} />
    }
  >
    <GoogleMap
      mapContainerStyle={mapContainer}
      center={initialCenter}
      zoom={zoom}
      options={{
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        controlSize: 30,
      }}
    >
      <Marker position={initialCenter} />
    </GoogleMap>
  </LoadScript>
);
