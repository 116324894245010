import { isBrowser } from '@firebase/util';

import { Tenant } from '@codegen/generated/graphql';

const GET_TENANT_ID_URL = new URL('tenant', process.env.NEXT_PUBLIC_REST_API);

GET_TENANT_ID_URL.searchParams.set(
  'domain',
  isBrowser() ? window.location.origin : '',
);

export const fetchTenant = async (): Promise<Tenant> => {
  const response = await fetch(GET_TENANT_ID_URL.toString());

  if (response.status === 200) {
    return await response.json();
  } else {
    throw new Error('Tenant ID cannot be obtained');
  }
};

const TENANT_SESSIONS_STORAGE_NAME = 'tenant';

export const getTenantFromStorage = (): Tenant | undefined => {
  if (!isBrowser()) return;

  const value = sessionStorage.getItem(TENANT_SESSIONS_STORAGE_NAME);

  if (value) {
    return JSON.parse(value);
  }

  return undefined;
};

export const setTenantInStorage = (tenant: Tenant) => {
  if (isBrowser()) {
    sessionStorage.setItem(
      TENANT_SESSIONS_STORAGE_NAME,
      JSON.stringify(tenant),
    );
  }
};
