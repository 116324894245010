import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/database';

import { Tenant } from '@codegen/generated/graphql';

import { Env, CUSTOM_ENV } from './env';

const configs = {
  [Env.STAGING]: {
    apiKey: 'AIzaSyAqdjgNjPhz8ibroCcqUkCVtzHuEivVq4c',
    authDomain: 'crave-staging.firebaseapp.com',
    databaseURL: 'https://crave-staging.firebaseio.com',
    projectId: 'crave-staging',
    storageBucket: 'crave-staging.appspot.com',
    messagingSenderId: '341409816227',
    appId: '1:341409816227:web:89e7645a3eb06ee1f1a916',
    measurementId: 'G-BJYCN91J82',
  },
  [Env.DEMO]: {
    apiKey: 'AIzaSyD2VvTPkitsnswSKcMe4_oR47wIvwsQZIE',
    authDomain: 'crave-demo-a6c18.firebaseapp.com',
    databaseURL: 'https://crave-demo-a6c18.firebaseio.com',
    projectId: 'crave-demo-a6c18',
    storageBucket: 'crave-demo-a6c18.appspot.com',
    messagingSenderId: '986180262122',
    appId: '1:986180262122:web:ecfa368fccdaf37cf01f93',
    measurementId: 'G-WMCWGYHEM6',
  },
  [Env.PRODUCTION]: {
    apiKey: 'AIzaSyCKeO2tugyfixPC-c7MlE478WWZzrl2KNA',
    authDomain: 'crave-production.firebaseapp.com',
    databaseURL: 'https://crave-production.firebaseio.com',
    projectId: 'crave-production',
    storageBucket: 'crave-production.appspot.com',
    messagingSenderId: '565640797699',
    appId: '1:565640797699:web:ebc15c56b32f4af466f836',
    measurementId: 'G-7SHQMJR85L',
  },
};

const firebaseConfig = configs[CUSTOM_ENV || configs.staging];

if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);

// check that `window` is in scope for the analytics module
let analytics: firebase.analytics.Analytics | null = null;
if (typeof window !== 'undefined') {
  if ('measurementId' in firebaseConfig) {
    analytics = firebase.analytics();
  }
}

export const auth = firebase.auth();
export const logEvent = analytics?.logEvent;

export const setTenantToAuth = (tenant: Tenant) => {
  if (!tenant.withoutTenantAuth) {
    auth.tenantId = tenant.id;
  }
};
