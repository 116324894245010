import React, { useState } from 'react';

import { uniq } from 'lodash';

import { Text } from '@components/Text';

import { LogoRow, KitchenLogo } from './styles';

interface Props {
  logos: string[];
}

export const KitchenLogos = ({ logos = [] }: Props) => {
  const [visible, setVisible] = useState(false);

  const uniqueLogos = uniq(logos);
  const needsTruncate = uniqueLogos.length > 11;
  const visibleLogos = uniqueLogos.slice(0, 12);
  const truncatedLogos = uniqueLogos.slice(12);

  return (
    <LogoRow>
      {visibleLogos.map((logo, i) => (
        <KitchenLogo key={`${i} - ${logo}`} src={logo} />
      ))}
      {visible &&
        truncatedLogos.map((logo, i) => (
          <KitchenLogo key={`${i} - ${logo}`} src={logo} />
        ))}
      {needsTruncate && (
        <Text onClick={() => setVisible(!visible)}>
          {!visible ? `+ ${truncatedLogos.length} More` : 'Hide'}
        </Text>
      )}
    </LogoRow>
  );
};
