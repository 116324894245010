import { useContext } from 'react';

import { useRouter } from 'next/router';

import { CartWidget } from '@containers/MainLayout/Header/CartWidget';
import { UserWidget } from '@containers/MainLayout/Header/UserWidget';
import { AppContext } from '@lib/appContext';
import { FacilityContext } from '@lib/facilityContext';
import { ROUTES } from '@utils/pages';

import * as S from './styles';

export const Header = () => {
  const { push } = useRouter();
  const { logoUrl } = useContext(FacilityContext);
  const { me } = useContext(AppContext);

  return (
    <S.HeaderContainer>
      <S.LogoFacilityUser>
        <S.LogoWrapper>
          <S.Logo
            src={logoUrl || '/images/restaurant-placeholder.svg'}
            onClick={() => push(ROUTES.LANDING_PAGE.route)}
          />
        </S.LogoWrapper>
        <S.WidgetWrapper>
          {!me?.isGuest && <UserWidget />}
          <CartWidget />
        </S.WidgetWrapper>
      </S.LogoFacilityUser>
    </S.HeaderContainer>
  );
};
