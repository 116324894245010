import { Text } from '@components/Text';

import { Props } from './types';

import { StyledButton } from './styles';

export const Button = ({
  text,
  type = 'primary',
  children,
  ...rest
}: Props) => {
  return (
    <StyledButton type={type} {...rest}>
      {text ? (
        <Text as="span" weight="bold">
          {text}
        </Text>
      ) : (
        children
      )}
    </StyledButton>
  );
};
