import { createContext, FC, useEffect, useContext, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import { message } from 'antd';
import { zonedTimeToUtc } from 'date-fns-tz';

import {
  useUserQuery,
  OrderMethod,
  useCustomerCartCostsQuery,
  useCustomerCartQuery,
  Tenant,
} from '@codegen/generated/graphql';
import { useStateManager } from '@lib/appContext/useStateManager';
import { isTimeslotActive } from '@lib/appContext/utils';
import { FacilityContext } from '@lib/facilityContext';
import { orderSummary } from '@utils/helpers';
import { CATEGORY_OPTIONS, KITCHEN_OPTIONS, Platform } from '@utils/types';

import { INITIAL_VALUES, ORDER_SUMMARY_DEFAULT_VALUES } from './constants';
import {
  getInitialValues,
  getExistingAddress,
  clearUrlKeysFromStorage,
} from './helpers';
import { DeliveryTime, IAppContextProps } from './types';
import { createValidation } from './validation';

export const AppContext = createContext<IAppContextProps>(INITIAL_VALUES);

export const AppContextProvider: FC<{
  platform: Platform;
  extraParams: string;
  tenant: Tenant;
}> = ({ children, platform, extraParams, tenant }) => {
  const { facilityTz } = useContext(FacilityContext);

  const {
    data: customerCartData,
    refetch: customerCartQuery,
    loading: customerCartLoading,
  } = useCustomerCartQuery();

  const customerCart = customerCartData?.me?.customerInfo?.cart;
  const cartItemsCount =
    customerCartData?.me?.customerInfo?.cart?.items.reduce(
      (acc, curr) => acc + curr.quantity,
      0,
    ) ?? 0;
  const isMobile = useMediaQuery({ query: '(max-width: 560px)' });

  const initialValues = getInitialValues();

  const {
    kitchenId: [kitchenId, setKitchenId],
    categoryId: [categoryId, setCategoryId],
    orderMethod: [orderMethod, setOrderMethod],
    orderEmail: [orderEmail, setOrderEmail],
    orderName: [orderName, setOrderName],
    orderPhone: [orderPhone, setOrderPhone],
    numberOfGuests: [numberOfGuests, setNumberOfGuests],
    address: [address, setAddress],
    note: [note, setNote],
    deliveryDate: [deliveryDate, setDeliveryDate],
    deliveryTime: [deliveryTime, setDeliveryTime],
    deliverTo: [deliverTo, setDeliverTo],
    preLoginMenuItem: [preLoginMenuItem, setPreLoginMenuItem],
    checkoutErrors: [checkoutErrors, setCheckoutErrors],
    calculatingCart: [calculatingCart, setCalculatingCart],
    orderConfirmationDisabled: [
      orderConfirmationDisabled,
      setOrderConfirmationDisabled,
    ],
    filters: [filters, setFilters],
  } = useStateManager(initialValues);

  const { data } = useUserQuery({
    onCompleted: (data) => {
      const me = data.me;

      if (!me?.isGuest) {
        setOrderName(me?.name ?? '');
        setOrderPhone(me?.phone ?? '');
        setOrderEmail(me?.email ?? '');
      }

      const customerInfo = data.me?.customerInfo;

      if (!!address && typeof address === 'string') {
        try {
          const parsed = JSON.parse(address);
          setAddress(parsed);
        } finally {
        }
      }

      if (address || !customerInfo?.addresses.length) return;
      let selectAddress = customerInfo.addresses[0];

      if (customerInfo?.defaultAddressId) {
        const defaultAddress = customerInfo.addresses.find(
          ({ id }) => id === customerInfo.defaultAddressId,
        );
        selectAddress = defaultAddress ?? selectAddress;
      }

      setAddress({
        placeId: selectAddress.placeId,
        label: selectAddress.formattedAddress || '',
        id: selectAddress.id,
        note: selectAddress.note,
      });
    },
  });
  const me = data?.me;

  const selectedAddress = getExistingAddress({
    existingAddresses: me?.customerInfo?.addresses.map(
      ({ formattedAddress, ...rest }) => ({
        ...rest,
        label: formattedAddress || '',
      }),
    ),
    currentAddress: address,
  });

  const { data: cartData, refetch: customerCartCost } =
    useCustomerCartCostsQuery({
      fetchPolicy: 'cache-and-network',
      skip: !me,
      variables: {
        orderMethod: orderMethod || OrderMethod.Delivery,
        placeId: address?.placeId || selectedAddress?.placeId,
      },
      // if there was an issue with calculation of the cost - forbid to pay for order until it's resolved
      onError: (e) => {
        message.error(e.message);
        setOrderConfirmationDisabled(true);
        setCalculatingCart(false);
      },
      // once the issue was resolved - allow to pay for order
      onCompleted: () => {
        setOrderConfirmationDisabled(false);
        setCalculatingCart(false);
      },
    });

  const summary = orderSummary({
    ...ORDER_SUMMARY_DEFAULT_VALUES,
    ...cartData?.customerCartCosts,
  });

  const basicCheckoutValidation = createValidation({
    orderMethod,
    deliveryDate,
    deliveryTime,
    selectedAddress,
    me,
    orderName,
    orderPhone,
    orderEmail,
    address,
  });

  useEffect(() => {
    setCalculatingCart(true);
    customerCartCost();
    customerCartQuery();
  }, [
    address?.id,
    orderMethod,
    customerCartCost,
    customerCartQuery,
    setCalculatingCart,
  ]);

  const resetCartContext = () => {
    setDeliverTo(INITIAL_VALUES.deliverTo);
    setDeliveryDate(INITIAL_VALUES.deliveryDate);
    setDeliveryTime(INITIAL_VALUES.deliveryTime);
    setNumberOfGuests(INITIAL_VALUES.numberOfGuests);
    clearUrlKeysFromStorage();
    if (me?.isGuest) {
      setOrderName('');
      setOrderPhone('');
    }
  };

  const deliveryDateTime =
    deliveryTime && zonedTimeToUtc(deliveryTime.end, facilityTz);

  const handleSetDeliveryTime = (value: DeliveryTime) => {
    // category and restaurant might not be available in the new timeslot, reset
    setCategoryId(CATEGORY_OPTIONS.First);
    setKitchenId(KITCHEN_OPTIONS.All);
    setDeliveryTime(value);
  };

  const isOrderForNow = useMemo(() => {
    if (!deliveryTime) return false;

    return isTimeslotActive(deliveryTime, facilityTz);
  }, [deliveryTime, facilityTz]);

  return (
    <AppContext.Provider
      value={{
        me,
        address,
        setAddress,
        note,
        setNote,
        orderMethod,
        setOrderMethod,
        deliveryDate,
        setDeliveryDate,
        deliveryTime,
        setDeliveryTime: handleSetDeliveryTime,
        isOrderForNow,
        kitchenId,
        setKitchenId,
        categoryId,
        setCategoryId,
        preLoginMenuItem,
        setPreLoginMenuItem,
        deliveryDateTime,
        deliverTo,
        setDeliverTo,
        basicCheckoutValidation,
        checkoutErrors,
        setCheckoutErrors,
        summary,
        calculatingCart,
        setCalculatingCart,
        platform,
        // @FIXME: seems useless, always comes empty, we should rather use "query" property from Next Router to path params over
        extraParams,
        cartItemsCount,
        customerCart,
        customerCartLoading,
        isMobile,
        filters,
        setFilters,
        resetCartContext,
        orderConfirmationDisabled,
        setOrderConfirmationDisabled,
        refetchCartCost: customerCartCost,
        orderName,
        setOrderName,
        orderPhone,
        setOrderPhone,
        orderEmail,
        setOrderEmail,
        numberOfGuests,
        setNumberOfGuests,

        tenant,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider');
  }

  return context;
};
