import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useApolloClient } from '@apollo/client';
import { Modal, message } from 'antd';
import { useRouter } from 'next/router';

import {
  useAllFacilitiesQuery,
  useAllKitchensQuery,
  useClearCartMutation,
} from '@codegen/generated/graphql';
import { Button } from '@components/Button';
import { Divider } from '@components/Divider';
import { SimpleConfirmModal } from '@components/Modals/SimpleConfirmModal';
import { Text } from '@components/Text';
import { AppContext } from '@lib/appContext';
import { FacilityContext } from '@lib/facilityContext';
import { ROUTES } from '@utils/pages';

import { FacilityInfo } from './components/FacilityInfo';
import { FacilityOptions } from './components/FacilityOptions';
import { sortByCreatedAt, getCuisinesList, getDeliveryInfo } from './utils';

import {
  ModalTitle,
  FlexColumn,
  FlexLine,
  Scroll,
  MobileButtonRow,
} from './components/styles';

interface Props {
  visible: boolean;
  onCancel: () => void;
}

export const LocationModal = ({ visible, onCancel }: Props) => {
  const { facilityId, setFacilityId, selectedFacility } =
    useContext(FacilityContext);
  const { cartItemsCount, customerCart, isMobile } = useContext(AppContext);

  const [tempFacility, setTempFacility] = useState(selectedFacility);
  const [resetModal, setResetModal] = useState(false);

  const { push, route } = useRouter();
  const { resetStore } = useApolloClient();
  const { data, loading, error } = useAllFacilitiesQuery();
  const { data: kitchenData, loading: kitchenLoading } = useAllKitchensQuery({
    variables: {
      facilityId: tempFacility?.id,
    },
  });
  const [clearCart] = useClearCartMutation();

  const sortedFacilities = useMemo(() => {
    return sortByCreatedAt(data?.customerFacilities ?? []);
  }, [data?.customerFacilities]);

  const isCurrentFacility = tempFacility?.id === facilityId;

  const changeFacility = useCallback(
    async ({ facilityId }: { facilityId: string }) => {
      if (!isCurrentFacility) {
        try {
          if (customerCart?.items && customerCart.items.length > 0)
            await clearCart();
        } catch (e) {
          message.error("Couldn't switch facility");
        }
        if (route !== ROUTES.MENU.route) {
          push(ROUTES.MENU.route);
        }
        resetStore();
      }
      setFacilityId(facilityId);
      onCancel();
    },
    [
      clearCart,
      customerCart?.items,
      isCurrentFacility,
      onCancel,
      push,
      resetStore,
      route,
      setFacilityId,
    ],
  );
  useEffect(() => {
    const tempFacility = sortedFacilities?.[0];
    if (!tempFacility?.id && tempFacility) {
      setTempFacility(tempFacility);

      if (sortedFacilities.length === 1) {
        changeFacility({
          facilityId: tempFacility.id,
        });
      }
    }
  }, [changeFacility, sortedFacilities, tempFacility]);

  useEffect(() => {
    if (!tempFacility && sortedFacilities.length >= 1) {
      setTempFacility(sortedFacilities[0]);
    }
  }, [sortedFacilities, tempFacility]);

  const cuisines = getCuisinesList(kitchenData?.customerKitchensAll);

  const onConfirmFacility = () => {
    if (!tempFacility) {
      return;
    }

    if (cartItemsCount > 0 && !isCurrentFacility) {
      setResetModal(true);
      onCancel();
    } else {
      changeFacility({
        facilityId: tempFacility.id,
      });
    }
  };

  const onOk = () => {
    if (!tempFacility) {
      return;
    }

    changeFacility({
      facilityId: tempFacility.id,
    });
    setResetModal(false);
  };

  const onFacilityClickFactory =
    (facility: typeof sortedFacilities[0]) => () => {
      setTempFacility(facility);
    };

  if (error) message.error('Unexpected error: ' + error.message);

  return (
    <>
      <Modal
        visible={visible}
        width={1000}
        bodyStyle={{ height: isMobile ? 'auto' : '750px', overflowY: 'auto' }}
        footer={null}
        closable={false}
      >
        <ModalTitle>
          <FlexColumn>
            <Text weight="bold" marginB="sm">
              Select a Location
            </Text>
          </FlexColumn>
        </ModalTitle>
        <Divider marginT="lg" marginB="lg" />

        <FlexLine>
          <Scroll>
            {sortedFacilities.map((facility) => {
              const { id, name, orderMethods, kitchenCount } = facility;
              const deliveryInfo = getDeliveryInfo(orderMethods);

              return (
                <FacilityOptions
                  key={id}
                  id={id}
                  name={name}
                  address={facility.formattedAddress}
                  numberOfKitchens={kitchenCount}
                  deliveryInfo={deliveryInfo}
                  isActiveFacility={tempFacility?.id === id}
                  onClick={onFacilityClickFactory(facility)}
                  loading={loading}
                  isMobile
                />
              );
            })}
            {isMobile ? (
              <MobileButtonRow>
                <Button
                  text="Confirm"
                  onClick={onConfirmFacility}
                  size="sm"
                  fluid={false}
                />
              </MobileButtonRow>
            ) : null}
          </Scroll>
          {tempFacility && !isMobile && (
            <FacilityInfo
              coordinates={{
                lat: tempFacility.lat,
                lng: tempFacility.lng,
              }}
              address={tempFacility.formattedAddress}
              cuisines={cuisines}
              kitchenLogos={tempFacility.kitchenLogos}
              onConfirm={onConfirmFacility}
              loading={kitchenLoading}
            />
          )}
        </FlexLine>
      </Modal>
      <SimpleConfirmModal
        visible={resetModal}
        setVisible={setResetModal}
        onConfirm={onOk}
        title="Changing your location will reset your cart."
        description="All locations have unique offerings and menus. By switching to a different location, all items in your cart will be removed."
        noButtonText="Go Back"
        yesButtonText="Ok"
      />
    </>
  );
};
