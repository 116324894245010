import styled from 'styled-components';

import { parseColor, SIZES, COLORS, parseMargin } from '@theme';

interface Props {
  lineColor?: COLORS;
  marginB?: SIZES;
  marginT?: SIZES;
}

export const Divider = styled.div(
  ({ lineColor = ['black', 0.1], marginB = 0, marginT = 0 }: Props) => `
    height: 1px;
    width: 100%;
    background: ${parseColor(lineColor)};
    margin-bottom: ${parseMargin(marginB)};
    margin-top: ${parseMargin(marginT)};
    display: block;
`,
);
