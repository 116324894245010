import styled, { css } from 'styled-components';
import { theme } from 'theme';

const { margin, color, padding } = theme;

const FlexRowCss = css`
  display: flex;
  flex-direction: row;
`;
const FlexColumnCss = css`
  display: flex;
  flex-direction: column;
`;

// Modals
export const ModalTitle = styled.div`
  height: 10%;
  width: 100%;
`;

export const Buttons = styled.div`
  ${FlexRowCss}
  justify-content: flex-end;
  margin-top: ${margin.lg};
`;

// Facility Options
export const FlexColumn = styled.div`
  ${FlexColumnCss}
`;

export const Scroll = styled.div`
  ${FlexColumnCss}
  overflow-y: auto;
`;

export const FacilityOption = styled.div<{
  isMobile?: boolean;
  active?: boolean;
}>(
  ({ isMobile, active }) => `
  ${FlexColumnCss}
  justify-content: center;
  align-items: start;

  min-width: ${isMobile ? '290px' : '365px'};
  min-height: 118px;
  background-color: ${color.springWood};
  border-radius: 10px;
  padding: ${padding.lg};
  margin-bottom: ${margin.lg};
  transition: 0.4s ease-in-out;
  box-shadow: 0 1px 2px rgba(0,0,0,0.15);
  border:  2px solid ${active ? 'black' : 'transparent'};

  :hover {
    box-shadow: 5px 5px 8px lightgray;
  }

`,
);

export const FlexLine = styled.div`
  ${FlexRowCss}
  height: 80%;
`;

// Facility Info
export const FacilityInfoSection = styled.div`
  ${FlexColumnCss}
  flex: 1;
  padding-left: 25px;
  overflow-y: auto;
`;

export const RightSectionRow = styled.div`
  ${FlexRowCss}
  margin-bottom: ${margin.lg};
`;

export const LogoRow = styled.div`
  ${FlexRowCss}
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 10px;
  margin-bottom: ${margin.xl};
  align-items: center;
`;

export const ButtonRow = styled.div`
  ${FlexRowCss}
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const MobileButtonRow = styled.div`
  justify-content: center;
  align-items: center;
`;

export const KitchenLogo = styled.img`
  max-height: 40px;
  width: auto;
  object-fit: contain;
`;
