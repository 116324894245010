import {
  CustomerCartCostsQuery,
  DashboardCategoriesQuery,
  DashboardKitchensQuery,
  FullMenuQuery,
  CartCosts,
  PromoValidationErrorWithCode,
  MenuScheduleCategoriesFragment,
  OrderValidationErrorResponseType,
} from '@codegen/generated/graphql';

export interface Geo {
  id?: string;
  placeId: string;
  label: string;
  note?: string | null;
}

export type GeoAddress = Geo | null;

export interface Gratuity {
  id: number | string;
  type: 'percent' | 'flat';
  value: string;
}

export type GratuityType = Gratuity | null;

export type DeliverTo =
  | 'me'
  | {
      name: string;
      email: string;
      phone: string;
    };

export type CheckoutErrors = {
  pickupOnlyItemIds: string[];
  unavailableItemIds: string[];
  errors: OrderValidationErrorResponseType[];
  promoErrors: PromoValidationErrorWithCode[];
};

export type OrderSummaryItem = {
  price?: number;
  quantity?: number;
};

export type OverallSummary = Pick<
  CartCosts,
  | 'subtotal'
  | 'taxFees'
  | 'delivery'
  | 'tip'
  | 'promoDiscount'
  | 'giftCardsDiscount'
  | 'total'
>;

export type OrderSummary = (
  cartCosts: CustomerCartCostsQuery['customerCartCosts'],
) => OverallSummary;

export type OrderItemType = 'mp-item' | 'normal-item';

export interface OrderItemDetails {
  id: string;
  name: string;
  label: string;
  quantity: number;
  price: number;
  type: OrderItemType;
  inventoryCount: number;
}

// TODO: Should be taken from the API
export enum Platform {
  Consumer = 'consumer',
  Catering = 'catering',
}

export interface Categories {
  id: string;
  name: string;
}

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type DashboardKitchen = ArrayElement<
  DashboardKitchensQuery['customerMenuKitchens']
>;

export type DashboardCategory = ArrayElement<
  DashboardCategoriesQuery['customerMenuCategories']
>;

export type DashboardMenuCategory = ArrayElement<
  ArrayElement<FullMenuQuery['customerFullMenu']>['menuCategories']
>;

export type DashboardMenuItem = ArrayElement<
  DashboardMenuCategory['menuItems']
>;

export enum CATEGORY_OPTIONS {
  All = 'all',
  First = 'first',
}

export enum KITCHEN_OPTIONS {
  All = 'all',
}

export type MenuCategories = Omit<
  MenuScheduleCategoriesFragment,
  'categoryType' | 'menuItemType'
>;

export type CheckoutForm = {
  fullName: string;
  phoneNumber: string;
  guestsCount: number;
  orderEmail: string;
};
