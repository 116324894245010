import styled from 'styled-components';

import { theme } from '@theme';

const { color } = theme;

export const CartButton = styled.button`
  background-color: ${color.lightGray};
  color: ${color.white};
  height: 42px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 16px;
  border-radius: 25px;
  border: none;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
`;

export const CartBadge = styled.div`
  min-width: 26px;
  height: 26px;
  border-radius: 13px;
  background-color: ${color.black};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;
