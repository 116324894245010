import { Modal } from 'antd';
import styled from 'styled-components';

import { Button } from '@components/Button';
import { Text } from '@components/Text';
import { theme } from '@theme';

const AntModal = styled(Modal)`
  .ant-modal-footer {
    border-top: 0 none;
  }
`;

const Footer = styled.div`
  display: flex;
  margin-top: ${theme.margin.md};
  justify-content: flex-end;
`;

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
  onConfirm: () => void;
  title: string;
  description: string;
  loading?: boolean;
  width?: number;
  noButtonText?: string;
  yesButtonText?: string;
}

export const SimpleConfirmModal = ({
  visible,
  setVisible,
  title,
  description,
  loading,
  onConfirm,
  noButtonText = 'Cancel',
  yesButtonText = 'Confirm',
  width = 600,
}: Props) => (
  <AntModal
    visible={visible}
    width={width}
    closable={false}
    footer={
      <Footer>
        <Button
          type="flat"
          size="sm"
          marginR="md"
          text={noButtonText}
          onClick={() => setVisible(false)}
          loading={loading}
          disabled={loading}
        />
        <Button
          type="primary"
          size="sm"
          text={yesButtonText}
          onClick={() => onConfirm()}
          loading={loading}
          disabled={loading}
        />
      </Footer>
    }
  >
    <Text size="h3" weight="bold" marginB="md">
      {title}
    </Text>
    <Text size="large" color="gray">
      {description}
    </Text>
  </AntModal>
);
